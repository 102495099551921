<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  @if (fieldViewMode == 'create') {
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Scored Questionnaire'"
        [placeholder]="moduleFieldString + '.scoredQuestionnaire.placeholder' | translate: 'Search Questionnaires'"
        [control]="formGroup?.controls?.questionnaire"
        [viewMode]="'create'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [targetTypes]="['QUESTIONNAIRE']"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'color']"
      >
      </app-target-code-selector>
    </div>
    <!-- <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="moduleFieldString + '.scoreStatus.label' | translate: 'Score Status'"
          [placeholder]="moduleFieldString + '.scoreStatus.placeholder' | translate: 'Select a Score Status'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="scoringEnums"
          [control]="formGroup?.controls?.scoreStatus"
          [viewMode]="'view'"
          [badgeView]="true"
        ></app-drop-down-input>
      </div> -->
  }
  @if (fieldViewMode != 'create') {
    <div class="formgrid grid p-fluid">
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.name.label' | translate: 'Name'"
          [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
          [control]="viewForm?.controls?.questionnaireName"
          [viewMode]="'view'"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.creatorName.label' | translate: 'Answered By'"
          [placeholder]="moduleFieldString + '.creatorName.placeholder' | translate: ''"
          [control]="viewForm?.controls?.creatorName"
          [viewMode]="'view'"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Scored Questionnaire'"
          [placeholder]="moduleFieldString + '.scoredQuestionnaire.placeholder' | translate: 'Search Questionnaires'"
          [control]="viewForm?.controls?.code"
          [viewMode]="'view'"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['QUESTIONNAIRE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.type.label' | translate: 'Type'"
          [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Search Types'"
          [control]="formGroup?.controls?.questionnaireType"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['QUESTIONNAIRE_TYPE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.campaign.label' | translate: 'Campaign'"
          [placeholder]="moduleFieldString + '.campaign.placeholder' | translate: 'Enter Campaign'"
          [control]="formGroup?.controls?.campaign"
          [viewMode]="'view'"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['CAMPAIGN']"
        >
        </app-target-code-selector>
      </div>
      <!-- <div class="col-12 md:col-6">
        <app-p-slider-input
          [name]="'score'"
          [label]="moduleFieldString + '.score.label' | translate: 'Score'"
          [placeholder]="moduleFieldString + '.score.placeholder' | translate: 'Enter Score'"
          [control]="formGroup?.controls?.score"
          [viewMode]="fieldViewMode"
          [min]="0"
          [max]="maxPassingScore"
        ></app-p-slider-input>
      </div>-->
      <!-- <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="moduleFieldString + '.scoreStatus.label' | translate: 'Score Status'"
          [placeholder]="moduleFieldString + '.scoreStatus.placeholder' | translate: 'Select a Score Status'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="scoringEnums"
          [control]="formGroup?.controls?.scoreStatus"
          [viewMode]="'view'"
          [badgeView]="true"
        ></app-drop-down-input>
      </div> -->
    </div>
    <!-- <p-divider align="left" class="col-12">
      <b>Questionnaire</b>
    </p-divider>
    <div class="formgrid grid p-fluid">
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.name.label' | translate: 'Name'"
          [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
          [control]="viewForm?.controls?.name"
          [viewMode]="'view'"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.type.label' | translate: 'Questions'"
          [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Enter Questions'"
          [control]="viewForm?.controls?.type"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['QUESTIONNAIRE_TYPE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-switch-input
          [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Scored Questionnaire'"
          [name]="'scoredQuestionnaire'"
          [viewMode]="'view'"
          [control]="viewForm?.controls?.scoredQuestionnaire"
        ></app-switch-input>
      </div>
      @if (viewForm?.controls?.scoredQuestionnaire?.value == true) {
        <div class="col-12 md:col-6">
          <app-number-input
            [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Passing Score'"
            [placeholder]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Passing Score...'"
            [name]="'passScore'"
            [showButtons]="true"
            [control]="viewForm?.controls?.passScore"
            [type]="'number'"
            [viewMode]="'view'"
            [maxFractionDigits]="0"
            [min]="0"
          ></app-number-input>
        </div>
      }
      <div class="col-12 md:col-12">
        <app-text-editor
          [height]="'100px'"
          [advanced]="true"
          [name]="'description'"
          [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Description'"
          [placeholder]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Enter Text...'"
          [control]="viewForm?.controls?.description"
          [viewMode]="'view'"
        ></app-text-editor>
      </div>
    </div> -->
    <!-- <p-divider align="left" class="col-12">
      <b>Answers</b>
    </p-divider> -->
  }

  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
