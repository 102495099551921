import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BaseForm,
  FieldDto,
  ModuleKeywords,
  QuestionFieldsDto,
  QuestionnaireAnswerDto,
  QuestionnaireQuestionsDto,
  RequestHandlerOptions,
  getEnumOptions
} from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { QuestionnaireDataService } from 'app/modules/questions/service/data/questionnaire-data.service';
import { QuestionsValueDataService } from 'app/modules/questions/service/data/questionnaire-value-data.service';

@Component({
  selector: 'app-questionnaire-value-form',
  templateUrl: './questionnaire-value-form.component.html',
  styleUrls: ['./questionnaire-value-form.component.scss'],
})
export class QuestionnaireValueFormComponent extends BaseForm<QuestionnaireAnswerDto> implements OnInit {

  scoringEnums = getEnumOptions(QuestionnaireAnswerDto.ScoreStatusEnum);
  maxPassingScore = 0;
  @Input()
  itemId: string;

  @Input()
  editMode: Boolean = false;



  _questions: QuestionFieldsDto[];
  get questions() {
    return this._questions;
  }
  @Input() set questions(value: QuestionFieldsDto[]) {
    this._questions = value;
    if (this.questions) {
      this.initDynamicFields();
    }
  };

  _questionnaire: QuestionnaireQuestionsDto;
  get questionnaire() {
    return this._questionnaire;
  }
  @Input() set questionnaire(value: QuestionnaireQuestionsDto) {
    this._questionnaire = value;
    if (this.questionnaire) {
      this.maxPassingScore = this.questionnaire.passScore;
      // if (this.formGroup.controls.score.value >= this.maxPassingScore) {
      //   this.formGroup.controls.scoreStatus.patchValue(QuestionnaireAnswerDto.ScoreStatusEnum.Pass);
      // } else {
      //   this.formGroup.controls.scoreStatus.patchValue(QuestionnaireAnswerDto.ScoreStatusEnum.Fail);
      // }
      this.viewForm.patchValue({ ...this.questionnaire, questionnaireName: this.questionnaire?.name ?? '' });
    }
  };


  _questionnaireValue: QuestionnaireAnswerDto;
  get questionnaireValue() {
    return this._questionnaireValue;
  }
  @Input()
  set questionnaireValue(value: QuestionnaireAnswerDto) {
    this._questionnaireValue = value;
    // this.viewForm.controls.questionnaireName.patchValue(this._questionnaireValue.name);
  }

  dynamicFields: FieldDto[] = [];
  @Input()
  loading: boolean = true;
  @Output() scoreStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() selectItem: EventEmitter<QuestionnaireQuestionsDto> = new EventEmitter();

  viewForm = new FormGroup({
    code: new FormControl(),
    name: new FormControl(null),
    // scoreStatus: new FormControl(),
    creatorName: new FormControl(null),
    type: new FormControl(null),
    description: new FormControl(null),
    passScore: new FormControl(null),
    scoringType: new FormControl(null),

    questionnaireName: new FormControl(null),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private questionnaireDataService: QuestionnaireDataService,
    private questionsValueDataService: QuestionsValueDataService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, ModuleKeywords.QuestionnaireValue);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  ngOnInit(): void { }


  // ngAfterViewInit(): void {
  //   if (this.editMode) {
  //     this.questionsValueDataService.getByIdOrCode<any>(this.itemId, this.apiOptions).subscribe((res) => {
  //       this.questions = res.data.answers;
  //       this.questionnaire = { code: res.data.questionnaire };
  //       this.questionnaireValue = res.data;
  //       this.id = res.data.id;
  //       this.data = res.data;
  //       // this.initDynamicFields();
  //       this.getQuestionnaire();
  //     });
  //   } else {
  //     // this.initDynamicFields();
  //     this.getQuestionnaire();
  //   }
  //   this._setupSubscriptions();
  // }

  // getQuestionnaire() {
  //   if (!this.questionnaire.code) {
  //     this.loading = false;
  //     return;
  //   }
  //   this.loading = true;
  //   this.questionnaireDataService.getByIdOrCode(this.questionnaire.code).subscribe({
  //     next: (res) => {
  //       this.loading = false;
  //       this.questions = res.data.questions;
  //       this.questionnaire = res.data;
  //       this.initDynamicFields();
  //       this.viewForm.patchValue({ ...this.questionnaire, questionnaireName: this.data?.name });
  //     },
  //     error: (err) => {
  //       this.loading = false;
  //     },
  //   });
  // }

  initDynamicFields() {
    // let exampleDynamicFieldNames = this.questions.map((q) => q.question);

    // exampleDynamicFieldNames.forEach((cName) => {
    //     this.formGroup.addControl(
    //         cName,
    //         new FormControl(null, Validators.required)
    //     );
    // });

    //@NOTE you can do custom logic to handle how to get the names and
    // how to instantiate the components based and what data you have
    // this is just a simple example
    this.dynamicFields = [];
    this.questions.forEach((q, i) => {
      q.fields.forEach((f) => {
        this.dynamicFields.push(f);
      });
    });
    // this.dynamicFields = this.questions.map((q, i) => {
    //     switch (q?.dynamicField?.type) {
    //         case DynamicField.TypeEnum.String: {
    //             return {
    //                 componentType: BasicInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Number: {
    //             return {
    //                 componentType: NumberInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {
    //                         numberInput: {
    //                             showButtons: true,
    //                         },
    //                     },
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Mcq: {
    //             return {
    //                 componentType: MCQComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {
    //                         radioInput: {
    //                             groupItems: q?.dynamicField?.options?.LIST_OPTIONS?.map((o) => {
    //                                 return {
    //                                     label: o,
    //                                     value: o,
    //                                 };
    //                             }),
    //                         },
    //                     },
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Boolean: {
    //             return {
    //                 componentType: SwitchInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {},
    //                 },
    //             };
    //         }
    //     }
    // });

    // if (!!this.editMode) {
    //     this.questions.forEach((q) => {
    //         if (!!q?.answer) {
    //             let obj = {};

    //             obj[q.question] = q.answer;

    //             this.formGroup.patchValue({
    //                 ...obj,
    //             });
    //         }
    //     });
    // }
  }

  getData() {
    let qs = this.questions;
    let formData = this.formGroup.getRawValue();

    // let answers: DynamicFiledValue[] = qs.map((q:DynamicFiledValue) => {
    //     return {
    //         question: q.question,
    //         questionType: q.type,
    //         options: q.options,
    //         answer: formData[q.question],
    //     };
    // });

    let data: QuestionnaireAnswerDto = {
      // answers: formData.answers,
      // code: null,
      // id:  this.questionnaireValue?.id,
      questionnaire: this.questionnaire?.code,
      // questionnaireCode: this.questionnaire?.code,
      questionnaireType: this.questionnaire?.type,
      // questionnaireName: this.questionnaire?.name,
    };

    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(data)
      : this.getChangedFormValues(data).updateItems;
  }

  setData(data: QuestionnaireAnswerDto) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    this.viewForm.patchValue({ ...this.questionnaire, questionnaireName: this.data?.name });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      // answers: new FormArray([new FormGroup({})]),
      questionnaire: new FormControl(null),
      scoreStatus: new FormControl(QuestionnaireAnswerDto.ScoreStatusEnum.Fail),
      score: new FormControl(0),
      campaign: new FormControl(null),
      questionnaireType: new FormControl(null)
    });
    this.formGroup.controls.questionnaire.valueChanges.subscribe(x => {
      this.selectItem.emit(x);
    });
  }
  scoredType = QuestionnaireQuestionsDto.ScoringTypeEnum.Scored;

  onScoreStatusChange(event) {
    this.scoreStatusChange.emit(this.questionnaire?.scoringType == this.scoredType ? event : null);
    this.formGroup?.controls?.scoreStatus?.patchValue(event);
  }
}
